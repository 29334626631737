import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginPage } from './pages/login';
import { ChatbotComponent } from './components/chatbot/chatbot.component'
import { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [showChatbot, setShowChatbot] = useState(false);
  const [token, setToken] = useState(null);
  const [language, setLanguage] = useState("es"); // Initialize with 'en'
  const [showLang, setShowLang] = useState(false); // Initialize with 'en'

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage); // Update the language state
  };

  const isLogged = ({ token }) => {
    setShowChatbot(true);
    setToken(token);
    setShowLang(true)
  }

  let view = <LoginPage isLogged={isLogged} />;
  if (showChatbot) {
    const element = window.document.querySelectorAll("[id*='gb']");
    if (element.length) {
      element[0].style.display = 'none';
    }
    
    view = <ChatbotComponent lang={language} token={token} />;
  }

  return (
    <div className="app-component">
      <HeaderComponent showLang={showLang} onLanguageChange={handleLanguageChange}/>
        {view}
      <FooterComponent positionAbsolute={showChatbot}/>
      <div className="gradient-container">
        <div className="bg-gradient" />
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0">
            <polygon className="fill-default"
              points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default App;
