import React, { useState } from "react";
import './language.css'

const LanguageComponent = ({onLanguageChange }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("es");

  const handleLanguageButtonClick = (language) => {
    onLanguageChange(language);
    setSelectedLanguage(language); 
    const message = `Language changed to English. Type in the chat to start.`;
    const mensaje = `Has cambiado el idioma a Español. Escribe en el chat para empezar.`;
    if(language === "en") {window.alert(message) } else {window.alert(mensaje)}
    if(language === "en") {document.querySelector("df-messenger").shadowRoot.querySelector("df-messenger-chat").shadowRoot.querySelector("df-messenger-user-input").shadowRoot.querySelector(".input-box-wrapper > input").placeholder = "Ask a question"; } else {document.querySelector("df-messenger").shadowRoot.querySelector("df-messenger-chat").shadowRoot.querySelector("df-messenger-user-input").shadowRoot.querySelector(".input-box-wrapper > input").placeholder = "Haz una pregunta";}
  };

  setTimeout(function(){ 
    
  }, 1000);

  return (
    <div className="language-component">
      <button className={`btn ${selectedLanguage === "en" ? 'selected' : ''}`} onClick={() => handleLanguageButtonClick("en")}>
        <img height={30} src="./uk.png" alt="logotipo" />
      </button>
      <button className={`btn ${selectedLanguage === "es" ? 'selected' : ''}`} onClick={() => handleLanguageButtonClick("es")}>
        <img height={30} src="./spain.png" alt="logotipo" />
      </button>
    </div>)
};

export default LanguageComponent;