import './header.css';
import LanguageComponent from '../language/language.component';


function HeaderComponent({showLang, onLanguageChange}) {
  return <div className="header-component">
    <a href="https://www.sinvisitas.com/"><img height={45} src="./logo.png" alt="logotipo" /></a>
    {showLang && (
    <LanguageComponent onLanguageChange={onLanguageChange} />
    )}
  </div>
}

export { HeaderComponent };