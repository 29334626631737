import axios from 'axios';

function loginController({ userId, password }) {
  return loginService({ userId, password })
    .then(({token}) => token)
    .catch(error => error);
}
 
function loginService({ userId, password }) {
  const body = { user: userId, password };
  const { REACT_APP_API_BASE_URL } = process.env;
  return axios.post(`${REACT_APP_API_BASE_URL}/auth/login`, body)
              .then(response => response.data)
              .catch(error => error);
}

export { loginController };