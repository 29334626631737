import React, { useState } from "react";
import {
  Card,
  CardContent,
  Button,
  CardActions,
  TextField,
  Divider,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Link,
} from "@material-ui/core";

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import { loginController } from '../services/login.service';
import './login.css';

const SVTextField = styled(TextField)({
  width: '100%',
  marginTop: '5%',
});

const SVButton = styled(Button)({
  width: '100%',
  color: '#fff',
  background: '#02b1c8',
  borderColor: '#02b1c8',
  letterSpacing: '.025em',
  fontSize: '.875rem',
  fontWeight: '600',
  boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
});

function LoginPage({ isLogged }) {
  const [showPassword, setShowPassword] = useState(false);
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false); 
  const [dialogMessage, setDialogMessage] = useState('');

  const onChangeUserId = (event) => {
    const { value } = event.target;
    setUserId(value);
  }

  const onChangePassword = (event) => {
    const { value } = event.target;
    setPassword(value);
  }

  const onLogin = async () => {
    try {
      const response = await loginController({ userId, password });
      console.log("This is the response", response);
      if (response === "El usuario no coincide con la contraseña especificada") {
        setDialogMessage("El usuario no coincide con la contraseña especificada. Puedes modificar la contraseña haciendo clic");
        setDialogOpen(true);
      } else if (response === "El usuario especificado no existe") {
        setDialogMessage("Usuario no identificado");
        setDialogOpen(true);
      } else {
        isLogged({ token: response });
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(true);
  }

  const handleMouseDownPassword = () => {
    setShowPassword(false);
  }

  const handleCloseDialog = () => {
    setDialogOpen(false); 
  };

  return (
    <div className="login-container">
      <div className="header-container">
        <h5 className="text text--white text--large">¡Hola!</h5>
        <p className="text text--light mt-5">
          Utiliza nuestro chatbot para gestionar tus incidencias como inquilino
        </p>
      </div>
      <div className="body-container">
        <Card>
          <CardContent>
            <div className="title-container">
              <h1 className="text text--black mb-5">Iniciar Sesión</h1>
            </div>
            <Divider />
            <div className="login-form">
              <p className="text mt-5" style={{ fontSize: "13px" }}>
                Accede con tu usuario y contraseña
              </p>
              <SVTextField
                type="text"
                label="Usuario"
                variant="filled"
                onChange={onChangeUserId}
              />
              <SVTextField
                type={showPassword ? "text" : "password"}
                label="Contraseña"
                variant="filled"
                autoComplete="current-password"
                onChange={onChangePassword}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            </div>
          </CardContent>
          <CardActions>
            <SVButton onClick={onLogin}>Iniciar Sesión</SVButton>
          </CardActions>
        </Card>
        <div>
          <a
            href="https://administraciononline.sinvisitas.com/#/reset-password"
            className="text"
          >
            ¿Has olvidado tu contraseña?
          </a>
        </div>
      </div>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Error de inicio de sesión</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            {dialogMessage.includes('clic') ? (
              <>
                {dialogMessage.split(' clic ')[0]}
                <Link
                  href="https://administraciononline.sinvisitas.com/#/reset-password"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-bold underline"
                >
                  {' aquí'}
                </Link>
                {dialogMessage.split(' clic ')[1]}
              </>
            ) : (
              dialogMessage
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export { LoginPage };