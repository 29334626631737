import { useEffect } from "react";
import "./chatbot.css";

function ChatbotComponent({ token, lang }) {
    useEffect(() => {
      const dfMessenger = document.querySelector('df-messenger');
      //console.log("This is the df messenger", dfMessenger)
      console.log("This is the shadowRoot", dfMessenger?.shadowRoot)
      var style = document.createElement( 'style' )
      style.innerHTML = '.df-messenger-wrapper { right: 50% !important}'
      dfMessenger?.shadowRoot?.appendChild( style )
      
      const component1 = dfMessenger?.shadowRoot
      var styleChat = document.createElement( 'style' )
      const dfMessengerChat = component1?.querySelector('df-messenger-chat')
      styleChat.innerHTML = '.chat-wrapper { right: 40% !important; bottom: 15% !important}'
      dfMessengerChat?.shadowRoot?.appendChild( styleChat )
  });   


  let view = null;
  if (token) {
    view = (
      <>
        <df-messenger
          chat-icon="/logo-sin.png"
          intent="WELCOME"
          chat-title="Sinvisitas PRE"
          //agent-id="8242e310-cf07-4c56-9ea6-5e31ddc72ae7"
          agent-id="84f74168-fb8d-4cfc-afc8-7d5c8302996f"
          language-code={lang}
          user-id={token}
          class="messenger"
          style={{"right": "50vw"}}
        >
        </df-messenger>
        
      </>
    );
  }
  return view;
}

export { ChatbotComponent };
