import './footer.css';
import { Grid } from '@material-ui/core';

function FooterComponent({ positionAbsolute}) {
  let containerClass = 'footer-component';

  if (positionAbsolute) {
    containerClass = 'footer-component footer-component--absolute';
  }

  return (
    <>
    <Grid container className={containerClass}>
      <Grid item xs={12} md={6} lg={6} className="footer-component__copywrite">
        <p className="text">© 2024 <a href="https://www.sinvisitas.com/" target="_blanc">Sin Visitas</a> - España</p>
      </Grid>
      <Grid item xs={12} md={6} lg={6} className="footer-component__legal">
        <a href="https://www.sinvisitas.com/legal" target="_blanc" className="text">Aviso Legal</a>
        <a href="https://www.sinvisitas.com/privacy" target="_blanc" className="text">Política de privacidad</a>
        <a href="https://www.sinvisitas.com/cookies" target="_blanc" className="text">Política de cookies</a>
      </Grid>
    </Grid>
    </>
   
  )
}

export { FooterComponent };